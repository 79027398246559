<template>
    <div>
        <div class="form-heading">
            <div class="form-heading-emoji-wrapper">
                <img src="/emoji/hammer.png" alt="smiling-img">
            </div>
            <div class="form-title">We're setting up your account!</div>
        </div>
        <div class="form-subheading">We need to gather reporting data from your GHL account.</div>
        <div class="mt-1 mb-2 estimate-time-text">
            <span> Estimated time:</span>
            <span class="font-weight-bolder">  {{ estimatedTime }}  </span>
        </div>

        <transition-group tag="div" name="fade" class="setup-account-steps hide-scrollbar mb-2">
            <div v-if="loading" key="steps-loader" class="locations-loading-spinner">
                <b-spinner variant="primary"/>
            </div>

            <div
                v-else
                class="setup-step"
                :class="{'completed': step.is_complete}"
                v-for="(step, stepIdx) in setupSteps"
                :key="stepIdx"
            >
                <div class="step-name">{{ step.name }}...</div>
                <div v-if="+currentStep === stepIdx">
                    <b-spinner class="step-loader" variant="primary"/>
                </div>
                <div v-else>
                    <svg v-if="step.is_complete" xmlns="http://www.w3.org/2000/svg" fill="#4bde80" width="20"
                         height="20" viewBox="0 0 20 20">
                        <path
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            fill-rule="evenodd" clip-rule="evenodd"></path>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" width="20" height="20"
                         viewBox="0 0 24 24"
                         stroke="#d1d5dc" stroke-width="2">
                        <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                    </svg>
                </div>
            </div>
        </transition-group>

        <b-button
            type="submit"
            class="w-100 mt-1 mt-md-2 submit-form-btn"
            :disabled="loading || currentStep <= setupSteps.length - 1"
            @click="$router.push({ name: 'dashboard' })"
        >
            <span v-if="loading || currentStep <= setupSteps.length - 1"
                  class="align-middle">Setting up account...</span>
            <span v-else>Go To Dashboard</span>
        </b-button>
    </div>
</template>

<script>
import AuthCardLeft from "@/layouts/components/auth/AuthCardLeft";
import toastsMixin from "@/mixins/toastsMixin";
import {BSpinner, BButton} from "bootstrap-vue";

export default {
    name: 'SetupAccount',
    components: {
        AuthCardLeft,
        BSpinner,
        BButton
    },
    mixins: [toastsMixin],
    mounted() {
        window.onbeforeunload = function () {
            return "Data will may be lost if you leave the page, are you sure?";
        };

        this.fetchSteps();
    },
    beforeDestroy() {
        window.onbeforeunload = null;
    },
    data() {
        return {
            loading: true,
            setupSteps: [],
            currentStep: 0
        }
    },
    computed: {
        estimatedTime() {
            return Math.ceil(this.$route.params.time) + ' minute' + (Math.ceil(this.$route.params.time) > 1 ? "s" : "");
        }
    },
    methods: {
        scrollStepsToBottom() {
            const stepContainer = document.querySelector('.setup-account-steps');
            stepContainer.scrollTop = stepContainer.scrollHeight;
        },
        async fetchSteps() {
            try {
                this.loading = true;
                const { data: { steps } } = await this.$http_client.get('/api/account/setup/steps')
                this.loading = false;

                this.setupSteps = steps.map(step => ({ name: step, is_complete: false }));
                const stepInterval = (this.$route.params.time * 60) / this.setupSteps.length;

                for (const step of this.setupSteps) {
                    const key = this.setupSteps.indexOf(step);
                    this.setupSteps[key].is_complete = true;
                    await new Promise(resolve => setTimeout(resolve, stepInterval * 1000));
                    this.currentStep += 1;
                }

                this.scrollStepsToBottom();
                this.showSuccessToast('Account setup successfully set up!');
            } catch (e) {
                this.loading = false;
                this.showErrorToast('Something went wrong');
            }
        }
    }
}
</script>


<style lang="scss">
.auth-layout.prepare-account-layout {
    .auth-form-card {
        .auth-form-right {
            color: black;

            .form-title {
                font-size: 20px;
                margin-bottom: 0;
            }

            .estimate-time-text {
                font-size: 16px;
            }

            .form-heading-emoji-wrapper img {
                height: 40px;
                width: 40px;
            }
        }

        .setup-account-steps {
            box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
            height: 360px;
            overflow-y: scroll;
            border-radius: 12px;
            border: 1px solid rgb(243, 244, 246);
            position: relative;

            .locations-loading-spinner {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .spinner-border {
                    width: 48px;
                    height: 48px;
                }
            }

            .setup-step {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 24px;
                border-bottom: 1px solid #e5e7eb;

                .step-name {
                    font-size: 14px;
                    font-weight: 500;
                    color: #d3d7dd;
                    transition: color 0.20s;
                }

                .step-loader {
                    width: 16px;
                    height: 16px;
                    border-width: 2px;
                }

                &:hover {
                    background-color: rgb(249, 250, 251);
                }

                &.completed .step-name {
                    color: #374151;
                }
            }

            .setup-step:last-child {
                border-style: none;
            }
        }
    }
}
</style>
